import React, { useContext, useEffect, useState } from 'react'
import { QuizContext } from '../contexts/quizContext'
import QuizChoice from './QuizChoice'

import { neighbouringNumbersTo, randomFrom } from '../helpers'

export default function TrueFalseChoices() {
  const { quiz, dispatch } = useContext(QuizContext)

  const [ wrongAnswer, setWrongAnswer ] = useState(null)

  const answerOptions = ['YES', 'NO']

  const updateCorrectAnswer = () => {
    dispatch({type: 'SET_CORRECT_ANSWER', answerOptions })
  }

  useEffect(() => {
    updateCorrectAnswer()
    setWrongAnswer( randomFrom( neighbouringNumbersTo( calculatedAnswer() ) ) )
  }, [quiz.target, quiz.multiplier])

  const trueIsCorrect = () => ((quiz.correctAnswer === 'TRUE') || (quiz.correctAnswer === 'YES'))
  const calculatedAnswer = () => quiz.target * quiz.multiplier

  return (
    <div className="choices col-md-12 true-false-choices">

      <h2 className="text-center">{ trueIsCorrect() ? calculatedAnswer() : wrongAnswer }</h2>

      { answerOptions.map( answerOption => (
        <QuizChoice  key={answerOption} answerOption={answerOption} />
      ) ) }

    </div>
  )
}
