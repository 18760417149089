import React from 'react';

import Header from './components/Header';
import QuizBox from './components/QuizBox';

import { QuizProvider } from './contexts/quizContext'

import './css/bootstrap.scss';
import './fonts/material-iconic-font/css/material-iconic-font.min.css';
import './fonts/ionicons/css/ionicons.min.css';
import './css/style.scss';
import './css/green.scss';
import './css/quiz.scss';

function App() {
  return (
    <QuizProvider>
      <Header />

      <QuizBox />
    </QuizProvider>
  );
}

export default App;
